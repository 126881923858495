@tailwind base;
@tailwind components;
@tailwind utilities;

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}

.swiper-slide {
  img {
    display: block;
    width: 100%;
  }
}
